import { computedFrom } from "aurelia-binding";
import { autoinject } from "aurelia-framework";
import { DataService } from "services/data-service";
import * as moment from "moment";
import Swal from "sweetalert2";

@autoinject
export class ReviewQueue {
    private _dataService: DataService;

    reviewQueueItems: any[] = [];
    isLoadingData: boolean = false;

    constructor(dataService: DataService) {
        this._dataService = dataService;
    }

    activate() {
        this.getReviewQueue();
    }

    async getReviewQueue() {
        this.isLoadingData = true;
        this.reviewQueueItems = await this._dataService.getReviewQueue(null);
        this.isLoadingData = false;
    }

    formatDate(date: Date) {
        return moment(date).format("D-MMM-YYYY h:mm:ss a");
    }

    @computedFrom("reviewQueueItems")
    get isQueueStuck() {
        var firstProcessedIndex = this.reviewQueueItems.findIndex(
            (item) => item.processed
        );

        var lastUnprocessedIndex = this.reviewQueueItems
            .slice()
            .reverse()
            .findIndex((item) => !item.processed);

        if (firstProcessedIndex < lastUnprocessedIndex) return true;

        return false;
    }

    deleteQueueItem(reviewQueueItem) {
        Swal.fire({
            icon: "warning",
            title: "Are you sure?",
            html: `<p>This will delete the review queue item.</p><p>Accession ID: ${reviewQueueItem.accession}</p><p> Unit ID: ${reviewQueueItem.unitId}</p>`,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
        }).then(async (result) => {
            if (result.value) {
                await this._dataService.deleteReviewQueueItem(reviewQueueItem);
                await this.getReviewQueue();
            }
        });
    }
}
